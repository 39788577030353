<template>
    <div class="vstack gap-3">
        <!-- Gambiarra pras cores do gráfico -->
        <span class="d-none bg-green"></span>
        <span class="d-none bg-red"></span>
        <span class="d-none bg-purple"></span>
        <span class="d-none bg-primary"></span>
        <span class="d-none bg-pink"></span>
        <span class="d-none bg-danger"></span>
        <span class="d-none bg-warning"></span>
        <span class="d-none bg-secondary"></span>
        <span class="d-none bg-success"></span>
        <!--  -->
        <div class="hstack gap-3 bg-white rounded py-3 px-4 justify-content-between">
            <div class="hstack gap-3">
                <div>
                    <b-iconstack font-scale="5">
                        <b-icon stacked icon="circle-fill" variant="green"></b-icon>
                        <b-icon stacked icon="megaphone-fill" scale="0.5" variant="white"></b-icon>
                    </b-iconstack>
                </div>
                <div>
                    <div class="h6 m-0">
                        Relatório de Campanha - {{ campaign?.name }}
                    </div>
                    <!-- <div class="text-secondary">
                        {{ totalLogs | decimalSpacing }} Acessos
                    </div> -->
                </div>
            </div>
            <!-- <div class="hstack gap-3">
                <b-dropdown ref="dropdownDate" menu-class="p-0 mt-2" toggle-class="border border-dark-subtle rounded-pill px-3 py-1 text-secondary small-text" variant="white" :text="dateFilterText">
                    <div class="hstack gap-2 p-3 w-max">
                        <b-form-radio class="d-inline-flex" v-model="filters.typeDate" name="radio-date" value="1">
                            <div class="ms-1 small-text">Hoje</div>
                        </b-form-radio>
                        <b-form-radio class="d-inline-flex" v-model="filters.typeDate" name="radio-date" value="2">
                            <div class="ms-1 small-text">Esta Semana</div>
                        </b-form-radio>
                        <b-form-radio class="d-inline-flex" v-model="filters.typeDate" name="radio-date" value="3">
                            <div class="ms-1 small-text">Este Mês</div>
                        </b-form-radio>
                        <b-form-radio class="d-inline-flex" v-model="filters.typeDate" name="radio-date" value="4">
                            <div class="ms-1 small-text">Período</div>
                        </b-form-radio>
                    </div>
                    <div class="vstack gap-3 p-3 pt-0" v-if="filters.typeDate">
                        <div class="vstack gap-3" v-if="filters.typeDate == 4">
                            <b-form-group label-class="text-secondary smaller-text lh-1" label="Data Inicial:">
                                <b-form-input type="date" v-model="filters.startDate"></b-form-input>
                            </b-form-group>
                            <b-form-group label-class="text-secondary smaller-text lh-1" label="Data Final:">
                                <b-form-input type="date" v-model="filters.endDate"></b-form-input>
                            </b-form-group>
                        </div>
                        <b-button class="text-white" variant="green" @click="closeDropdownDate">Fechar</b-button>
                    </div>
                </b-dropdown>
                <b-dropdown ref="dropdownType" menu-class="p-0 mt-2" toggle-class="border border-dark-subtle rounded-pill px-3 py-1 text-secondary small-text" variant="white" :text="filters.filter.type == 'department' ? 'Departamento' : filters.filter.type == 'operator' ? 'Operador' : 'Filtrar por'">
                    <div class="hstack gap-2 p-3 w-max">
                        <b-form-radio class="d-inline-flex" v-model="filters.filter.type" name="radio-type" value="department" @change="filters.filter.value = null">
                            <div class="ms-1 small-text">Departamento</div>
                        </b-form-radio>
                        <b-form-radio class="d-inline-flex" v-model="filters.filter.type" name="radio-type" value="operator" @change="filters.filter.value = null">
                            <div class="ms-1 small-text">Operador</div>
                        </b-form-radio>
                    </div>
                    <div class="p-3 pt-0 vstack gap-3" v-if="filters.filter.type">
                        <b-form-select class="w-100 rounded" style="border-color: #ccc; padding: 0.4em 0" v-model="filters.filter.value" v-if="filters.filter.type == 'department'">
                            <b-form-select-option :value="dep._id" v-for="dep in departments" :key="dep._id">{{dep.name}}</b-form-select-option>
                        </b-form-select>
                        <b-form-select class="w-100 rounded" style="border-color: #ccc; padding: 0.4em 0" v-model="filters.filter.value" v-else-if="filters.filter.type == 'operator'">
                            <b-form-select-option :value="op._id" v-for="op in operators" :key="op._id">{{op.name}}</b-form-select-option>
                        </b-form-select>
                        <b-button class="text-white" variant="green" @click="closeDropdownType">Fechar</b-button>
                    </div>
                </b-dropdown>
                <b-button class="rounded-pill py-0 px-4 text-white fw-semibold" variant="green" @click="getData(1)" :disabled="!filters.typeDate || !filters.filter.value">
                    Gerar
                </b-button>
            </div> -->
        </div>
        <div class="p-5 text-center bg-white rounded" v-if="loadingData">
            <b-spinner></b-spinner>
        </div>
        <div class="vstack gap-3" v-else-if="campaign">
            <b-row class="m-0 gap-3">
                <b-col class="d-flex flex-column gap-2 align-items-center py-4-5 px-4 rounded shadow-sm" style="background-color: hsl(231.67deg 37.11% 61.96%)">
                    <b-iconstack font-scale="6">
                        <b-icon stacked icon="circle-fill" style="color: hsl(230.34deg 78.38% 78.24%)"></b-icon>
                        <b-icon stacked icon="cursor" scale="0.5" style="color: hsl(231.67deg 37.11% 61.96%)" />
                    </b-iconstack>
                    <div class="text-white fs-4">
                        Total de Envios
                    </div>
                    <div class="fw-semibold fs-5" style="color: hsl(230.34deg 78.38% 78.24%)">
                        {{ counts.totalSent || 0 }}
                    </div>
                </b-col>
                <b-col class="d-flex flex-column gap-2 align-items-center py-4-5 px-4 rounded shadow-sm" style="background-color: hsl(9.36deg 56.63% 51.18%)" role="button" @click="getData('read')">
                    <b-iconstack font-scale="6">
                        <b-icon stacked icon="circle-fill" style="color: hsl(9.83deg 100% 77.25%)"></b-icon>
                        <b-icon stacked icon="eye" scale="0.5" style="color: hsl(9.36deg 56.63% 51.18%)"></b-icon>
                    </b-iconstack>
                    <div class="text-white fs-4">
                        Total de Visualizações
                    </div>
                    <div class="fw-semibold fs-5" style="color: hsl(9.83deg 100% 77.25%)">
                        {{ counts.totalReading || 0 }}
                    </div>
                </b-col>
                <b-col class="d-flex flex-column gap-2 align-items-center py-4-5 px-4 rounded shadow-sm" style="background-color: hsl(86.97deg 35.46% 49.22%)" role="button" @click="getData('response')">
                    <b-iconstack font-scale="6">
                        <b-icon stacked icon="circle-fill" style="color: hsl(85.71deg 55.68% 65.49%)"></b-icon>
                        <b-icon stacked icon="percent" scale="0.5" style="color: hsl(86.97deg 35.46% 49.22%)" />
                    </b-iconstack>
                    <div class="text-white fs-4">
                        Total de Retorno
                    </div>
                    <div class="fw-semibold fs-5" style="color: hsl(85.71deg 55.68% 65.49%)">
                        {{ counts.totalResponse || 0 }} - {{  formatPercentage(counts.totalResponse, counts.totalSent) || 0 }}%
                    </div>
                </b-col>
                <b-col class="d-flex flex-column gap-2 align-items-center py-4-5 px-4 rounded shadow-sm" style="background-color: hsl(39.15deg 76.64% 58.04%)">
                    <b-iconstack font-scale="6">
                        <b-icon stacked icon="circle-fill" style="color: hsl(39.3deg 100% 72.16%)"></b-icon>
                        <b-icon stacked icon="exclamation-lg" scale="0.5" style="color: hsl(39.15deg 76.64% 58.04%)" />
                    </b-iconstack>
                    <div class="text-white fs-4">
                        Total de falhas
                    </div>
                    <div class="fw-semibold fs-5" style="color: hsl(39.3deg 100% 72.16%)">
                        {{ campaign.errorSending ? counts.total : counts.alreadyInAttendance || 0 }}
                    </div>
                </b-col>
            </b-row>
            <div class="bg-white rounded shadow-sm">
                <b-row class="m-0 py-3 px-4 border-bottom-grey">
                    <b-col class="fw-bold p-0">
                        Nome da Campanha
                    </b-col>
                    <b-col class="fw-bold p-0">
                        Data/Hora de Envio
                    </b-col>
                    <b-col class="fw-bold p-0">
                        Data/Hora de Finalização
                    </b-col>
                    <b-col class="fw-bold p-0">
                        Status
                    </b-col>
                    <b-col class="fw-bold p-0">
                        Chatbot
                    </b-col>
                    <b-col class="fw-bold p-0">
                        Público
                    </b-col>
                    <b-col class="fw-bold p-0">
                        Direcionamento
                    </b-col>
                </b-row>
                <b-row class="m-0 py-3 px-4 align-items-center">
                    <b-col class="p-0 text-truncate">
                        <div class="text-truncate">
                            {{ campaign.name }} 
                        </div>
                    </b-col>
                    <b-col class="p-0 text-truncate">
                        <div>
                            <div class="text-truncate">{{ campaign.sendingMessages.immediate ? campaign.createdAt : campaign.sendingMessages.schedulingDate | date('DD/MM/YYYY HH:mm:ss') }}</div>
                        </div>
                    </b-col>
                    <b-col class="p-0">
                        <span v-if="campaign.finalShippingTime">
                            {{ campaign.finalShippingTime | date('DD/MM/YYYY HH:mm:ss') }}
                        </span>
                        <span v-else>
                            - - - -
                        </span>
                    </b-col>
                    <b-col class="p-0 text-truncate">
                        <div class="hstack gap-2">
                            {{ campaign.status }}
                            <div v-b-tooltip.hover.bottom="{ variant: 'purple', customClass: 'top-0', boundary: 'document' }" title="Mantenha contato com nossa equipe de suporte!" v-if="campaign.status === 'Falha no Envio'" role="button">
                                <b-iconstack class="darkenTextOnHover" role="button" font-scale="1.3"> 
                                    <b-icon class="text-purple" stacked icon="circle-fill"></b-icon>
                                    <b-icon stacked class="text-white" icon="info"></b-icon>
                                </b-iconstack>
                            </div>
                        </div>
                    </b-col>
                    <b-col class="p-0 text-truncate">
                        <div class="">
                            <b-icon icon="x" font-scale="2" v-if="campaign.automation.enable" />
                            <b-icon icon="check" font-scale="2" v-else />
                        </div>
                    </b-col>
                    <b-col class="p-0 text-truncate">
                        <div>
                            <span v-if="campaign.contactsType == 0">
                                Todos
                            </span>
                            <span v-else-if="campaign.contactsType == 1">
                                Seleção de Contatos
                            </span>
                            <span v-else-if="campaign.contactsType == 2">
                                Gênero
                            </span>
                            <span v-else-if="campaign.contactsType == 3">
                                Etiquetas
                            </span>
                            <span v-else-if="campaign.contactsType == 4">
                                Planilha
                            </span>
                            <span v-else-if="campaign.contactsType == 5">
                                Carteira de Operador
                            </span>
                            <span v-else>- - - -</span>
                        </div>
                    </b-col>
                    <b-col class="p-0 text-truncate">
                        <span v-if="campaign.automation?.enable">
                            <span v-if="campaign.automation.action === 'operator'">
                                {{ operators.find(el => el._id === campaign.automation.value)?.name }}
                            </span>
                            <span v-else-if="campaign.automation.action === 'department'">
                                {{ departments.find(el => el._id === campaign.automation.value)?.name }}
                            </span>
                        </span>
                        <span v-else>- - - -</span>
                    </b-col>
                </b-row>
            </div>
            <div class="p-5 text-center bg-white rounded" v-if="loadingContactsData">
                <b-spinner></b-spinner>
            </div>
            <div class="bg-white rounded shadow-sm" v-else-if="contactsSheet?.length">
                <b-row class="m-0 py-3 px-4 border-bottom-grey">
                    <b-col class="fw-bold p-0">
                        Contato
                    </b-col>
                    <b-col class="fw-bold p-0">
                        Telefone
                    </b-col>
                    <b-col class="fw-bold p-0">
                        Data/Hora de Envio
                    </b-col>
                    <b-col class="fw-bold p-0">
                        Data/Hora de Leitura
                    </b-col>
                    <b-col class="fw-bold p-0">
                        Abriu atendimento
                    </b-col>
                    <b-col class="fw-bold p-0" cols="1"></b-col>
                </b-row>
                <div v-for="(data, i) of contactsSheet" :key="data._id">
                    <b-row class="m-0 py-3 px-4 align-items-center" :class="{'border-bottom-grey': i < contactsSheet.length - 1}">
                        <b-col class="p-0 text-truncate">
                            <div class="d-flex align-items-center text-truncate">
                                <div class="me-2">
                                    <b-avatar class="bg-secondary text-white" size="3rem" :src="data.photoURL"></b-avatar>
                                </div>
                                <div class="text-truncate">
                                    {{ data.name }}
                                </div>
                            </div>
                        </b-col>
                        <b-col class="p-0 text-truncate">
                            <div>
                                {{ data.number }} 
                            </div>
                        </b-col>
                        <b-col class="p-0">
                            <span v-if="data.sentTime || campaign.createdAt">
                                {{ (data.sentTime || (campaign.sendingMessages.immediate ? campaign.createdAt : campaign.sendingMessages.schedulingDate)) | date('DD/MM/YYYY HH:mm:ss') }}
                            </span>
                            <span v-else>
                                - - - -
                            </span>
                        </b-col>
                        <b-col class="p-0">
                            <span v-if="data.readingTime">
                                {{ data.readingTime | date('DD/MM/YYYY HH:mm:ss') }}
                            </span>
                            <span v-else>
                                - - - -
                            </span>
                        </b-col>
                        <b-col class="p-0">
                            <div class="">
                                <b-icon icon="x" font-scale="2" v-if="!data.responseTime" />
                                <b-icon icon="check" font-scale="2" v-else />
                            </div>
                        </b-col>
                        <b-col class="p-0" cols="1">
                            <div class="d-flex justify-content-end gap-3">
                                <b-button class="bg-green border-0 rounded-circle px-2" @click="selectContact(data); loadMessages(data.attendanceId)" v-b-modal.contactMessages v-b-tooltip.hover.bottomright="{ variant: 'green', customClass: 'top-0', boundary: 'document' }" title="Visualizar Atendimento" v-if="data.responseTime">
                                    <b-icon class="mt-1" icon="chat-square-text" scale="0.9"></b-icon>
                                </b-button>
                            </div>
                        </b-col>
                    </b-row>
                </div>
            </div>
            <div class="d-flex justify-content-between align-items-center px-4 py-3 bg-white rounded" v-if="contactsSheet && contacts?.length > perPage">
                <b-pagination class="m-0"
                    :total-rows="contacts.length"
                    v-model="currentPage"
                    :per-page="perPage"
                    @change="pageChange"
                ></b-pagination>
                <span class="pagdescription">
                    <div class="text-secondary">{{ ( currentPage > 1 ) ? ((currentPage) * perPage) - perPage + 1 : currentPage }} - {{ (currentPage * perPage > contacts.length) ? contacts.length : currentPage * perPage }} de {{ contacts.length }} contatos</div>
                </span>
            </div>
        </div>
        <!-- <div class="p-5 text-center bg-white rounded text-secondary" v-else-if="loadedData">
            Nenhum dado encontrado!
        </div> -->
        <FAB :screenshotBtn="true" />
        <b-modal id="contactMessages" ref="contactMessages" title="Visualizar Atendimento" header-class="py-0" hide-footer>
            <div class="d-flex align-items-center mb-2" v-if="selectedContact">
                <b-avatar class="bg-secondary text-white" size="3rem" :src="selectedContact.photoURL"></b-avatar>
                <div class="text-purple fw-bold ms-2">
                    {{ selectedContact.name }} 
                </div>
            </div>
            <div>
                <Whatsapp :messages="contactMessages" :height="'72vh'" :mediaURL="mediaURL" />
            </div>
        </b-modal>
    </div>
</template>

<script>
import api from '../services/apiService'
import utils from '../utils/utils'
import FAB from './FAB.vue'
import Whatsapp from './whatsapp.vue'

export default {
    props: [
        "user", 
        "timestampDiff",
        "mediaURL"
    ],
    components: {
        FAB,
        Whatsapp,
    },
    mounted() {
        this.getOperators()
        this.getDepartments()
        this.getData()
        this.onCloseModal()
    },
    data() {
        return {
            campaignId: this.$route.query.id,
            campaign: null,
            contacts: null,
            contactsSheet: [],
            operators: [],
            departments: [],
            chartData: null,
            loadingData: false,
            loadingContactsData: false,
            loadedData: false,
            currentPage: 1,
            perPage: 10,
            selectedContact: {},
            contactMessages: [],
            protocol: {},
            counts: {},
        }
    },
    computed: {
        dateFilterText() {
            switch(this.filters.typeDate) {
                case 1:
                case '1':
                    return 'Hoje'
                case 2:
                case '2':
                    return 'Esta Semana'
                case 3:
                case '3':
                    return 'Este Mês'
                case 5:
                case '5':
                    return 'Este Ano'
                default:
                    return 'Período'
            }
        },
    },
    methods: {
        async getData(filter = null)  {
            this.loadingData = true
            const resp = await api.getReportCampaign(this.campaignId, filter)

            if(resp.statusCode == 200) {
                this.campaign = resp.reportCampaign.campaign

                if (this.campaign.cancel)
                    this.campaign.status = "Cancelada"
                else if (this.campaign.errorSending)
                    this.campaign.status = "Falha no Envio"
                else if (this.campaign.sendingMessages.schedulingDate && Date.now() < new Date(this.campaign.sendingMessages.schedulingDate).getTime())
                    this.campaign.status = "Aguardando Envio"
                else 
                    this.campaign.status = this.campaign.finalShippingTime ? "Finalizada" : "Em Andamento"

                this.counts = {
                    totalCount: resp.reportCampaign.total,
                    totalSent: resp.reportCampaign.sent,
                    totalFailed: resp.reportCampaign.alreadyInAttendance,
                    totalReading: resp.reportCampaign.totalReading,
                    totalResponse: resp.reportCampaign.totalResponse,
                }

                this.contacts = resp.reportCampaign.campaign.contacts.filter(contact => contact.sent)

                this.getContactsData(1)
            } else {
                this.campaign = null
                this.counts = {}
                this.chartData = null
            }
            
            this.loadingData = false

            if(!this.loadedData)
                this.loadedData = true
        },
        async getContactsData(page = 1) {
            this.loadingContactsData = true

            let contacts = this.paginate(this.contacts, this.perPage, page)

            const promise = contacts.map(async contact => {
                const resp = await api.getContact(contact.contactId)

                if (resp.statusCode === 200) {
                    return {
                        ...contact,
                        _id: resp.contact._id,
                        name: resp.contact.name,
                        number: resp.contact.number,
                        photoURL: resp.contact.photoURL,
                    }
                }

                return contact
            })
            contacts = await Promise.all(promise)

            if (page != this.currentPage) {
                this.currentPage = page
            }

            this.contactsSheet = contacts

            this.loadingContactsData = false
        },
        paginate(array, page_size, page_number) {
            return array.slice((page_number - 1) * page_size, page_number * page_size)
        },
        async getOperators() {
            const resp = await api.getOperators(this.user.channelId || this.user.roleId)
            
            if(resp.statusCode != 200) {
                this.operators = []
                return
            }

            if(this.user.role == "channel_manager" && this.user.departments?.at(0) !== 'all')
                resp.operators = resp.operators.filter(el => this.user.departments?.some(e => el.department.includes(e)))

            this.operators = resp.operators
        },
        async getManagers() {
            const resp = await api.getManagers(this.user.channelId || this.user.roleId)

            if(resp.statusCode != 200) {
                this.managers = []
                return
            }

            this.managers = resp.managers
        },
        async getDepartments() {
            const resp = await api.getDepartments(this.user.channelId || this.user.roleId)
            if(resp.statusCode != 200) {
                this.departments = []
                return
            }

            if(this.user.role == "channel_manager" && this.user.departments?.at(0) !== 'all')
                resp.departments = resp.departments.filter(el => this.user.departments?.includes(el._id))

            this.departments = resp.departments
        },
        selectLog(log) {
            this.selectedLog = log
        },
        roleFormat(role) {
            switch(role) {
                case "channel_manager":
                    return "Supervisor"
                case "channel":
                    return "Canal"
                case "operator":
                    return "Operador"
                case "admin":
                    return "Admin"
                default:
                    return "- - - -"
            }
        },
        estadoCompletoParaSigla(estado) {
            return utils.estadoCompletoParaSigla(estado)
        },
        pageChange(e) {
            if(e != this.currentPage) {
                this.currentPage = e
                this.getContactsData(e)
            }
        },
        onCloseModal() {
            this.selectedLog = null
        },
        closeDropdownDate() {
            this.$refs.dropdownDate.hide(true)
        },
        closeDropdownType() {
            this.$refs.dropdownType.hide(true)
        },
        getKanbanName(kanbanId) {
            const kanban = this.kanbans.find(el => el._id == kanbanId)
            if(kanban)
                return kanban.name
            else
                return ""
        },
        formatPercentage(value, total) {
            const n = ((value / total) || 0) * 100
            if(Number.isInteger(n)) {
                return n
            }
            return n.toFixed(1)
        },
        parseState(state) {
            return utils.siglaPraEstadoCompleto(state) || 'N/D'
        },
        parseCountry(countryCode) {
            return utils.getCountryName(countryCode) || 'N/D'
        },
        parseGender(gender) {
            const genderMap = {
                'M': 'Masculino',
                'F': 'Feminino',
            }
            return genderMap[gender] || 'Não Identificado'
        },
        formatTime(ms) {
            if (ms && ms > 1000) {
                const pad = (n) => (n < 10 ? '0' + n : n)

                const days = Math.floor(ms / (24 * 60 * 60 * 1000))
                const hours = Math.floor((ms % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000))
                const minutes = Math.floor((ms % (60 * 60 * 1000)) / (60 * 1000))
                const minutesms = ms % (60*1000)
                const sec = Math.floor(minutesms / 1000);

                const formattedTime = days ? `${pad(days)}:${pad(hours)}:${pad(minutes)}:${pad(sec)}` : `${pad(hours)}:${pad(minutes)}:${pad(sec)}`

                return formattedTime
            }
            return "00:00:00:00";
        },
        selectContact(item) {
            this.selectedContact = item
        },
        async loadMessages(attendanceId) {
            const resp = await api.loadMessages({
                attendance: {
                    _id: attendanceId
                },
            })

            if (resp.statusCode === 200) {
                resp.messages.messages.map(el => {
                    if(this.timestampDiff > 100)
                        el.timestamp -= this.timestampDiff
    
                    if(el.timestamp > 9999999999)
                        el.timestamp /= 1000
                })
    
                this.contactMessages = resp.messages.messages
            }
        },
    }
}
</script>

<style>
    .reportContacts td, .reportContacts th{
        padding: 1em 1.5em !important;
    }
</style>